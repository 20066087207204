





































import { Component, Vue } from 'vue-property-decorator'
import  firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import '../vuefire'

@Component({
  firebase () {
    const db =  firebase.database()
    const user =  firebase.auth().currentUser

    return {
      name: {
        source: db.ref('users').child(user ? user.uid : 'no-user').child('data').child('name'),
        asObject: true
      },
      address: {
        source: db.ref('users').child(user ? user.uid : 'no-user').child('data').child('address'),
        asObject: true
      },
      mailTo: {
        source: db.ref('users').child(user ? user.uid : 'no-user').child('data').child('mailTo'),
        asObject: true
      }
    }
  }
})
export default class Settings extends Vue {
  private name: { '.value': string, '.key': string } = { '.value': '', '.key': '' }
  private address: { '.value': string, '.key': string } = { '.value': '', '.key': '' }
  private mailTo: { '.value': string, '.key': string } = { '.value': '', '.key': '' }

  saveName () {
    return this.$firebaseRefs.name.set(this.name['.value'])
  }

  saveAddress () {
    return this.$firebaseRefs.address.set(this.address['.value'])
  }

  saveMailTo () {
    return this.$firebaseRefs.mailTo.set(this.mailTo['.value'])
  }
}
